/**
 * Location/Store -> All Displays -> Display Sets (date ranges) -> Timeslots (times & days of week) -> Content (URLs)
 */
export const displayData = [
    {
        "store_id": 164,
        "version": 1,
        "displays": [
            {
                "display_num":1,
                "orientation":"landscape", // portrait | landscape
                "display_name":"Menu 1",
                "display_description":"Left Menu Board",
                "playlists": [
                    {
                        "start_date":"2023-07-21", // Y-m-d | null
                        "end_date":"2023-08-31",   // Y-m-d | null
                        "timeslots": [
                            {
                                "timeslot_name":"Breakfast",
                                "transition":"slideLeft",  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"04:30:00",    // 24 hour timestamp
                                "end_time":"22:24:59",      // 24 hour timestamp
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-Breakfast.jpg",  // full (http...) or relative (/images/...)
                                        "rotate":10, // integer (seconds) | null
                                    },
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-Kids-Sides.jpg",
                                        "rotate":10, // integer (seconds) | null
                                    }
                                ]
                            },
                            {
                                "timeslot_name":"Lunch-Dinner",
                                "transition":null,
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"22:25:00",
                                "end_time":"23:59:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/menus/default/Bakers-American.jpg",
                                        "rotate":null,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "start_date":"2129-07-21", // Y-m-d | null
                        "end_date":"2129-08-31",   // Y-m-d | null
                        "timeslots": [
                            {
                                "timeslot_name":"Breakfast",
                                "transition":null,  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"04:30:00",    // 24 hour timestamp
                                "end_time":"19:24:59",      // 24 hour timestamp
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-Breakfast.jpg",
                                        "rotate":15, // integer (seconds) | null
                                    },
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-Kids-Sides.jpg",
                                        "rotate":15, // integer (seconds) | null
                                    }
                                ]
                            },
                            {
                                "timeslot_name":"Lunch-Dinner",
                                "transition":null,  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"19:25:00",
                                "end_time":"23:59:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-American.jpg",
                                        "rotate":null,
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "display_num":2,
                "orientation":"landscape",
                "display_name":"Menu 2",
                "display_description":"Center Menu Board",
                "playlists": [
                    {
                        "start_date":"2023-07-21", // Y-m-d | null
                        "end_date":"2023-08-31",   // Y-m-d | null
                        "timeslots": [
                            {
                                "timeslot_name":"Breakfast",
                                "transition":null,  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"04:30:00",
                                "end_time":"11:29:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-American.jpg",
                                        "rotate":15,       // integer | null
                                    }
                                ]
                            },
                            {
                                "timeslot_name":"Lunch-Dinner",
                                "transition":null,  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"11:30:00",
                                "end_time":"23:59:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-Mexican.jpg",
                                        "rotate":null,
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "display_num":3,
                "orientation":"landscape",
                "display_name":"Menu 3",
                "display_description":"Right Menu Board",
                "playlists": [
                    {
                        "start_date":"2023-07-21", // Y-m-d | null
                        "end_date":"2023-08-31",   // Y-m-d | null
                        "timeslots": [
                            {
                                "timeslot_name":"Breakfast",
                                "transition":null,  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"04:30:00",
                                "end_time":"11:29:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-Mexican.jpg",
                                        "rotate":null,       // integer | null
                                    }
                                ]
                            },
                            {
                                "timeslot_name":"Lunch-Dinner",
                                "transition":null,  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"11:30:00",
                                "end_time":"23:59:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-Kids-Sides.jpg",
                                        "rotate":null,
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "display_num":4,
                "orientation":"portrait",
                "display_name":"Promo 1",
                "display_description":"Left Promo Board",
                "playlists": [
                    {
                        "start_date":"2023-07-21", // Y-m-d | null
                        "end_date":"2023-08-31",  // Y-m-d | null
                        "timeslots": [
                            {
                                "timeslot_name":"All-Day",
                                "transition":null,  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"00:00:00",
                                "end_time":"23:59:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-Beverages.jpg",
                                        "rotate":null,       // integer | null
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "display_num":5,
                "orientation":"portrait",
                "display_name":"Promo 2",
                "display_description":"Right Promo Board",
                "playlists": [
                    {
                        "start_date":"2023-07-21", // Y-m-d | null
                        "end_date":"2023-08-31",   // Y-m-d | null
                        "timeslots": [
                            {
                                "timeslot_name":"Breakfast",
                                "transition":"slideDown",  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"00:10:00",
                                "end_time":"11:29:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-Promos.jpg",
                                        "rotate":15,       // integer | null
                                    },
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-Specials.jpg",
                                        "rotate":5,       // integer | null
                                    }
                                ]
                            },
                            {
                                "timeslot_name":"Lunch-Dinner",
                                "transition":"slideLeft",  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"11:30:00",
                                "end_time":"23:59:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-Promos.jpg",
                                        "rotate":10,       // integer | null
                                    },
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/v2/Bakers-Specials.jpg",
                                        "rotate":10,       // integer | null
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
        ]       
    },
    {
        "store_id": 169,
        "version": 1,
        "displays": [
            {
                "display_num":1,
                "orientation":"landscape", // portrait | landscape
                "display_name":"Menu 1",
                "display_description":"Left Menu Board",
                "playlists": [
                    {
                        "start_date":"2023-07-21", // Y-m-d | null
                        "end_date":"2999-08-31",   // Y-m-d | null
                        "timeslots": [
                            {
                                "timeslot_name":"AllDay",
                                "transition":null,
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"05:30:00",
                                "end_time":"23:59:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/20241218/American.jpg",
                                        "rotate":null,
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "display_num":2,
                "orientation":"landscape",
                "display_name":"Menu 2",
                "display_description":"Center Menu Board",
                "playlists": [
                    {
                        "start_date":"2024-03-06", // Y-m-d | null
                        "end_date":"2999-08-31",   // Y-m-d | null
                        "timeslots": [
                            {
                                "timeslot_name":"AllDay",
                                "transition":null,  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"05:30:00",
                                "end_time":"23:59:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/20241218/Mexican.jpg",
                                        "rotate":null,
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "display_num":3,
                "orientation":"landscape",
                "display_name":"Menu 3",
                "display_description":"Right Menu Board",
                "playlists": [
                    {
                        "start_date":"2024-03-06", // Y-m-d | null
                        "end_date":"2999-08-31",   // Y-m-d | null
                        "timeslots": [
                            {
                                "timeslot_name":"Breakfast",
                                "transition":null,  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"05:00:00",
                                "end_time":"11:00:00",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/20241218/Breakfast.jpg",
                                        "rotate":15,       // integer | null
                                    },
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/20241218/Kids-Sides.jpg",
                                        "rotate":15,       // integer | null
                                    }
                                ]
                            },
                            {
                                "timeslot_name":"Lunch",
                                "transition":null,  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"11:00:01",
                                "end_time":"17:00:00",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/20241218/Kids-Sides.jpg",
                                        "rotate":15,
                                    },
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/20241218/Promo-Meals.jpg",
                                        "rotate":15,       // integer | null
                                    }
                                ]
                            },
                            {
                                "timeslot_name":"Dinner",
                                "transition":null,  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"17:00:01",
                                "end_time":"23:59:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/20241218/Kids-Sides.jpg",
                                        "rotate":15,
                                    },
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/20241218/Budget.jpg",
                                        "rotate":15,       // integer | null
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "display_num":4,
                "orientation":"horizontal",
                "display_name":"Promo 1",
                "display_description":"Left Promo Board",
                "playlists": [
                    {
                        "start_date":"2023-07-21", // Y-m-d | null
                        "end_date":"2023-08-31",  // Y-m-d | null
                        "timeslots": [
                            {
                                "timeslot_name":"All-Day",
                                "transition":null,  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"00:05:00",
                                "end_time":"16:59:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/20241218/Drinks-Desserts-v2.jpg",
                                        "rotate":15,       // integer | null
                                    },
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/20241218/Budget.jpg",
                                        "rotate":15, // integer (seconds) | null
                                    }
                                ]
                            },
                            {
                                "timeslot_name":"All-Day",
                                "transition":null,  // null | slideLeft | slideRight | slideUp | slideDown | fade
                                "week_days":"Mon:Tue:Wed:Thu:Fri:Sat:Sun",
                                "start_time":"17:00:00",
                                "end_time":"23:59:59",
                                "content": [
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/20241218/Drinks-Desserts-v2.jpg",
                                        "rotate":15,       // integer | null
                                    },
                                    {
                                        "url":"https://digital-displays.s3.us-west-2.amazonaws.com/20241218/Promo-Meals.jpg",
                                        "rotate":15, // integer (seconds) | null
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
        ]       
    },
];